// app/javascript/packs/application.js
import * as bootstrap from "bootstrap";
import "../stylesheets/application";
import $ from "jquery";
// import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

// Make Bootstrap and jQuery globally available
window.bootstrap = bootstrap;
window.jQuery = window.$ = $;

document.addEventListener("turbolinks:load", function () {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
});

// Only start Rails UJS if it hasn't been started yet
if (typeof Rails !== "undefined" && Rails.fire) {
  Rails.start();
} else {
  console.warn("Rails UJS has already been loaded or is not available.");
}

Turbolinks.start();
ActiveStorage.start();

// Load linear_issues.js after everything else
import "./linear_issues";

// // app/javascript/packs/application.js
// import * as bootstrap from "bootstrap";
// import "../stylesheets/application";
// import $ from "jquery";
// import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
// import * as ActiveStorage from "@rails/activestorage";
// import "channels";

// // Make Bootstrap and jQuery globally available
// window.bootstrap = bootstrap;
// window.jQuery = window.$ = $;

// document.addEventListener("turbolinks:load", function () {
//   var tooltipTriggerList = [].slice.call(
//     document.querySelectorAll('[data-bs-toggle="tooltip"]')
//   );
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl);
//   });
// });

// Rails.start();
// Turbolinks.start();
// ActiveStorage.start();

// // Load linear_issues.js after everything else
// import "./linear_issues";
