// app/javascript/packs/linear_issues.js

window.generateTableHTML = function(issues, includeCheckbox = false) {
  if (issues.length === 0) {
    return '<p class="text-center">No issues found.</p>';
  }

  let html = `
    <div class="table-responsive">
      <table class="table" id="issues-table">
        <thead>
          <tr>
            ${includeCheckbox ? '<th><input type="checkbox" class="select-all" /></th>' : ''}
            <th class="sortable" data-sort="identifier">Identifier</th>
            <th class="sortable" data-sort="branch">Branch</th>
            <th class="sortable" data-sort="app">App</th>
            <th class="sortable" data-sort="title">Title</th>
            <th class="sortable" data-sort="assignee">Assignee</th>
            <th>Deployment Notes</th>
            <th class="sortable" data-sort="status">Status</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
  `;

  issues.forEach(issue => {
    html += `
      <tr data-issue-id="${issue.id}">
        ${includeCheckbox ? `<td><input type="checkbox" class="issue-checkbox" data-id="${issue.id}" /></td>` : ''}
        <td>${issue.identifier}</td>
        <td>${issue.branchName || 'N/A'}</td>
        <td>${window.renderAppBadges(issue.labels)}</td>
        <td>${issue.title}</td>
        <td>${issue.assignee ? issue.assignee.name : 'Unassigned'}</td>
        <td>${issue.deployment_notes || 'No deployment notes'}</td>
        <td>${issue.state.name}</td>
        <td><a href="${issue.url}" target="_blank">View in Linear</a></td>
      </tr>
    `;
  });

  html += `
        </tbody>
      </table>
    </div>
  `;

  return html;
};


window.renderAppBadges = function(labels) {
  if (!labels || !labels.nodes || labels.nodes.length === 0) return 'No Label';

  return labels.nodes.map(label => {
    if (['mf_store', 'mf_admin', 'mf_api_server'].includes(label.name)) {
      return `<span class="badge badge-${label.name}">${label.name}</span>`;
    } else if (label.name.toLowerCase().includes('checkout')) {
      return '<span class="badge badge-checkout">Checkout</span>';
    } else {
      return label.name;
    }
  }).join(' ');
};



window.renderLatestComment = function (comments) {
  if (!comments || !comments.nodes || comments.nodes.length === 0)
    return "No comments";

  const lastComment = comments.nodes[0];
  return `
    <div class="markdown-content">
      ${lastComment.body}
      <small>${new Date(lastComment.createdAt).toLocaleString()}</small>
    </div>
  `;
};

window.setupSorting = function (table) {
  if (!table) return;

  const headers = table.querySelectorAll("th.sortable");
  headers.forEach((header) => {
    header.addEventListener("click", () => {
      const column = header.dataset.sort;
      const isAscending = header.classList.contains("asc");

      headers.forEach((h) => h.classList.remove("asc", "desc"));
      header.classList.add(isAscending ? "desc" : "asc");

      const rows = Array.from(table.querySelectorAll("tbody tr"));
      const sortedRows = rows.sort((a, b) => {
        const aValue = a
          .querySelector(
            `td:nth-child(${Array.from(headers).indexOf(header) + 1})`
          )
          .textContent.trim();
        const bValue = b
          .querySelector(
            `td:nth-child(${Array.from(headers).indexOf(header) + 1})`
          )
          .textContent.trim();

        if (aValue < bValue) return isAscending ? -1 : 1;
        if (aValue > bValue) return isAscending ? 1 : -1;
        return 0;
      });

      table.querySelector("tbody").innerHTML = "";
      sortedRows.forEach((row) =>
        table.querySelector("tbody").appendChild(row)
      );
    });
  });
};

window.updateCount = function (state, count) {
  const countSpan = document.getElementById(
    `${state.toLowerCase().replace(/\s+/g, "-")}-count`
  );
  if (countSpan) {
    countSpan.textContent = ` (${count})`;
  }
};

window.styleAppSections = function () {
  document.querySelectorAll(".app-header").forEach((header) => {
    const app = header.dataset.app;
    header.style.backgroundColor = window.appColors[app];

    const title = header.querySelector(".app-title");
    if (title) {
      title.style.color = "white";
    }

    header.querySelectorAll(".btn-release").forEach((button) => {
      button.style.backgroundColor = window.appColors[app];
      button.style.borderColor = "white";
      button.style.borderWidth = "2px";
      button.style.borderStyle = "solid";
      button.style.color = "white";

      button.addEventListener("mouseenter", function () {
        this.style.backgroundColor = window.lightenColor(
          window.appColors[app],
          0.1
        );
      });

      button.addEventListener("mouseleave", function () {
        this.style.backgroundColor = window.appColors[app];
      });
    });
  });
};

window.lightenColor = function (color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(
            255,
            Math.max(0, parseInt(color, 16) + amount * 255)
          ).toString(16)
        ).substr(-2)
      )
  );
};

window.darkenColor = function (color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(
            255,
            Math.max(0, parseInt(color, 16) - amount * 255)
          ).toString(16)
        ).substr(-2)
      )
  );
};

window.appColors = {
  mf_api_server: "#8FBCBB",
  mf_admin: "#81a1c1",
  mf_store: "#D04648",
};

window.renderDeploymentNotes = function(comments) {
  if (!comments || !comments.nodes || comments.nodes.length === 0) return 'No deployment notes';

  for (let comment of comments.nodes) {
    const match = comment.body.match(/@deploymentNotes@([\s\S]*?)@@deploymentNotes@/);
    if (match) {
      return `
        <div class="deployment-notes">
          ${match[1].trim()}
        </div>
      `;
    }
  }

  return 'No deployment notes found';
};


document.addEventListener("turbolinks:load", () => {
  const filterButtons = document.querySelectorAll(".btn-group .btn");
  const issuesTable = document.getElementById("issues-table");

  if (filterButtons && issuesTable) {
    filterButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault();
        const state = button.dataset.state;

        // Update active button
        filterButtons.forEach((btn) => btn.classList.remove("active"));
        button.classList.add("active");

        // Filter table rows
        const rows = issuesTable.querySelectorAll("tbody tr");
        rows.forEach((row) => {
          if (state === "all" || row.dataset.state === state) {
            row.style.display = "";
          } else {
            row.style.display = "none";
          }
        });
      });
    });
  }
});
